import * as React from 'react'
import {
    noteClass
  } from './layout.module.css'


const Note = ({ children }) => {
  return (
    <div className={noteClass}>
      <h4>Note</h4>
      {children}</div>
  )
}

export default Note