import * as React from 'react'
import {
    line
  } from './layout.module.css'


const Line = ({ children }) => {
  return (
    <p className={line}>{children}</p>
  )
}

export default Line