import * as React from 'react'
import {
    explication,
  } from './layout.module.css'




const Explication = ({ children }) => {
  return (
    <div className={explication}>
        {children}
    </div>
  )
}

export default Explication