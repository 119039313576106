import * as React from 'react'
import { Link } from 'gatsby'


const relatedTerm = ({ relatedEntryID, relatedClass, relatedTermTitle }) => {
  return (
    <Link to={relatedEntryID} className={relatedClass}>
        {relatedTermTitle}
    </Link>
  )
}

export default relatedTerm