import * as React from 'react'
import Layout from '../components/layout'
import {content, illustrative, illustration, small, paragraph, real, relPhrase, relWord, relValue} from '../components/layout.module.css'
import Explication from '../components/explication'
import Line from '../components/line'
import { Link } from 'gatsby'
import Note from '../components/note'
import Example from '../components/example'
import RelatedTerm from '../components/relatedTerm'



const HelpPage = () => {
  return (
    <Layout pageTitle="How to use this Dictionary">
      <div className={content}>
	  <p class={paragraph}>This is an introduction to the different features of entries in this dictionary website. If you would like more information about the AusDICT, please visit <Link to="/about-ausdict">About the AusDICT</Link> for an introduction to the AusDICT and Standard Translatable English. 
</p>
		<h5>Entries</h5>
		<p class={paragraph}>
			There are several different types of entries in this dictionary. Broadly speaking, they can be categorised in three different ways. <br/><br/>
			The first category is words as you might find in a traditional dictionary. These words represent important concepts to speakers of Australian English. <br/><br/>
			The second category is phrases, these entries provide both the literal and the pragmatic meaning of the phrase. <br/><br/>
			The third category, is values, which represent the inner perspectives of speakers of Australian English. <br/><br/>
			These values range from those which influence how we think, down to the kinds of attitudes and expectations which influence the way we speak to one another. <br/><br/>
			It is important to note that these values do not represent rules for interacting, rather they represent a shared understanding amongst Australians. 
			That understanding can then be used and changed as required by individuals.</p>
			<p class={paragraph}>
				Below are some examples of entries and their features.  
			</p>
		
		<h5>Searching</h5>
		<p>
			Entries in this dictionary are not necessarily titled as one might expect. There are five key ways of finding information in this dictionary: </p>
		<ol class="ol">
			<li>
				<h6>By Topic</h6>
				<p>The <Link to="/by-topic">By Topic</Link> menu lists the major domains covered by this dictionary. Each major topic has its own page which lists the entries sorted into sub-topics. 
				Use these topics when planning classes around broad themes, or around particular speech routines.</p>
			</li>
			<li>
				<h6>Index by parts of speech</h6>
				<p>As described below, the entries provide a 'part of speech' component. In the <Link to="/part-of-speech">By Part Of Speech</Link> menu you can find all the entries grouped by their part of speech. This is particularly useful for finding particular values, attitudes, and norms; especially if the entry titles seem unfamiliar to you.</p>
			</li>
			<li>
				<h6>Entry Index</h6>
				<p>If you know the title of the entry you are looking for, all entries are listed alphabetically by title in the <Link to="/entry-index">Entry Index</Link>.</p>
			</li>

			<li>
				<h6>Search</h6>
				<p>You can also use <Link to="/search">Search</Link> to find entries. In search, you will find every instance of a word or term, including if it has been used in one of the notes or examples. Search is very useful if you are looking for a particular word, or you know that the word you are looking for does not have an entry in the dictionary, but may be referenced elsewhere.</p>
			</li>
		</ol>


		<h5>Entry content</h5>
		<p class={paragraph}> Each entry represents only a single meaning of the word or phrase. Words with multiple meanings are listed individually; however, not all meanings of every word are represented in this dictionary.</p>
			
			<h6>Entry title</h6>
			<p class={paragraph}>The entry title has been chosen to be the most likely reference point for you, the reader. The entry title is at the top of the page. It is searchable.</p>
			
			<div class={illustrative}>			
				<h2 class={illustration}>tough attitude</h2></div>

			<h6>Parts of speech</h6>
			<p class={paragraph}>Following the entry title, each entry contains a part of speech description. 
			Many of these parts of speech will be familiar to you (such as nouns and verbs) however, many of these are less likely to be familiar as parts of speech—such as values and attitudes. 
			Each part of speech has been elaborated on in STE to contextualise the following entry information.</p>
			<p class={paragraph}>These parts of speech do not directly correspond to the types of information mentioned above. 
			In particular, the attitudes, norms, and values, are all part of the 'values' category.</p>
			<p class={paragraph}>By clicking on "see more" next to the description of the part of speech, you can visit a list of all entries in that part of speech category.</p>
			<div class={illustrative}>
		<h2 class={illustration}>good sport "this person is a good sport" </h2>
		<h3>adjective: a kind of person<Link to="/adjective_person" className={small}>(see more)</Link></h3><br></br><br></br>
		<h2 class={illustration}>how to make a reasonable request</h2>
		<h3>norm: when it is like this, it is good to do some things<Link to="/norm" className={small}>(see more)</Link></h3><br></br><br></br>
		<h2 class={illustration}>tough attitude</h2>
		<h3>attitude: some people can think like this about something<Link to="/attitude_thing" className={small}>(see more)</Link></h3>
		</div>

			
			<h6>STE in entries</h6>
			<p class={paragraph}>As discussed on the <Link to="/about-ausdict">About the AusDICT</Link> page, 
			the entries are described using Standard Translatable English, a small subset of words in English which have equivalents in all, 
			or nearly all, languages. 
			This means that any of the descriptions contained in the Dictionary can be used with students from all language and education backgrounds, 
			while retaining the reliability of the information. Because the subset of STE is constrained, it is important to note that the word choice is very deliberate. 
			e.g. 'someone can think something like this' is different from 'someone thinks something like this'.</p>
			
			
		<h6>In text name examples</h6>
			<p class={paragraph}>Throughout many of the entries, personal names have been used as examples for the people taking part in the interaction. 
			This is to give the entries more context and easier readability. 
			Unless specifically stated, these names are not representative of any gender, and can be placed by any preferred names. 
			In some cases, pronouns in the text match the gender of the example name, but this is intended to be changed as you need.</p> 
		
		<h5>Notes</h5>
		<p class={paragraph}>The notes in this dictionary are designed to clarify certain features of the entry, such as the wording, 
		the perspective, or what is and isn't captured. Some notes also give suggestions for ideas to explore with students in classes. 
		Notes appear in tan boxes, with a single darker tan border on the left.</p>
		
		<div class={illustrative}>
			<h2 class={illustration}>bloke</h2>
		<h3>noun: a person of one kind<Link to="/noun_person" className={small}>(see more)</Link></h3>

		<Explication>
<Line lineNumber="1">A man.</Line>
<Line lineNumber="2">People think about this man like this: This man is someone like many other people. This man is someone like me. He can do many good things. At many times, he does many good things for other people, not because he wanted them to think good things about him. People can think many good things about this man.'</Line>
<Line lineNumber="3">People think it is good to be someone like this.</Line>
</Explication>

<Note>'Bloke' is often associated with the working class, however it is used by people of all classes to refer to people of their own class, thus this social hierarchy element has not been included here. The related adjective 'blokey' is somewhat different in implications, in that it contains more of the 'masculine' elements than the noun does i.e. 'this person does many things in the same way as many men do things. When people look at this man, they can say 'this man is a man like many other men.'' </Note>


		</div>

		<h5>Examples</h5>
		<p class={paragraph}>The examples in this dictionary are not historical examples, but are contextual examples designed to illustrate the situations in which the word and expression of a particularly entry is typically used. 
		Many of the sources are news articles which discuss recent and current events and issues. 
		Examples appear in bold italic text, in tan boxes.</p>
		
		<div class={illustrative}>
<h2 class={illustration}>mateship</h2>
		<h3>value: many people in Australia think it is good if people can think like this<Link to="/value" className={small}>(see more)</Link>
		</h3>
		<Explication>
<Line lineNumber="1">Many people in Australia think like this:</Line>
<Line lineNumber="2">It is good to think like this about many people: This person is someone like me. At many times, when something happens to me, the same something happens to this person. I don't want bad things to happen to this person, as I don't want bad things to happen to me. I feel something good when I think about this person.</Line>
<Line lineNumber="3">When someone (e.g. Frank) thinks like this about a person (e.g. Colin), it is good if Frank thinks like this at the same time: When something bad happens to Colin, I can do something good for him. If I do something good for Colin, maybe he can think like this: I don't have to feel something bad now. There are many people like me in this place now. I feel something good towards them. Because of this, I can feel something good. I can laugh.</Line>
<Line lineNumber="4">It is very good for men to think like this about other men.</Line>
</Explication>

<Note>Note that this version of 'mateship' is the one in which two (or more) people have the same thing happening to them (e.g. during WWI), and the most current usage extends from this prototypical one. Line 3 highlights the strong connection of this term to male and masculine relationships.</Note>

<Example exampleDate="2017" exampleKind={real} exampleAuthor="McGrindle" exampleSource="" exampleSourceID="38" hansardID="" otherID="">And on average they've most likely experienced and contributed to the great Australian value of community and mateship. It is a 'come in for a cuppa' culture that gives a 'no worries' welcome to someone regardless of how average or not they might be.</Example>

<Example exampleDate="1935" exampleKind={real} exampleAuthor="McKinney" exampleSource="" exampleSourceID="10" hansardID="" otherID="">The one compensating aspect of life as then lived was the element of mateship. Inside the wide family circle of the battalion and the company were the more closely knit platoon groups.</Example>

		</div>
		<h5>Cross-referencing</h5>
		<p class={paragraph}>This dictionary makes extensive use of cross-referencing on each entry. 
		These cross references have been linked throughout each entry and contain both similar entries and opposites. 
		The cross references are in green boxes underneath each entry and include values, words, and phrases. Simply click on the link to go to that entry.</p>
		
		<div class={illustrative}>
			<h2 class={illustration}>fair go</h2>
		<h3>
			value: many people in Australia think it is good if people can think like this<Link to="/value" className={small}>(see more)</Link>
		</h3>

		<Explication>
<Line lineNumber="1">All people want good things. This is good.</Line>
<Line lineNumber="2">Good things can happen to people if they do good things.</Line>
<Line lineNumber="3">All people can do good things.</Line>
<Line lineNumber="4">People can’t say “Good things can’t happen to you.” if people do good things.</Line>
</Explication>



<Example exampleDate="2005" exampleKind={real} exampleAuthor="AND" exampleSource="" exampleSourceID="17" hansardID="" otherID="">In the land of the 'fair go' for all, two million are living in poverty.</Example>

<Example exampleDate="2011" exampleKind={real} exampleAuthor="AND" exampleSource="" exampleSourceID="17" hansardID="" otherID="">Voting for same-sex marriage is a vote for equality, and a vote for a fair go for all lesbian, gay, bisexual and transgender Australians.</Example>

<Example exampleDate="2014" exampleKind={real} exampleAuthor="AND" exampleSource="" exampleSourceID="17" hansardID="" otherID="">The actions by Immigration Minister Scott Morrison of transferring people fleeing persecution back to those they fear raises serious questions about the direction this policy area is heading. Such a decision indicates a lack of a more compass which casts our reputation as the land of the fair go and respect for human rights adrift.</Example>

<RelatedTerm relatedEntryID="/entry17" relatedClass={relPhrase} relatedTermTitle="that's not fair"/>

<RelatedTerm relatedEntryID="/entry18" relatedClass={relPhrase} relatedTermTitle="that's fair"/>

<RelatedTerm relatedEntryID="/entry46" relatedClass={relWord} relatedTermTitle="battler"/>

<RelatedTerm relatedEntryID="/entry170" relatedClass={relValue} relatedTermTitle="women in positions of authority"/>

<RelatedTerm relatedEntryID="/entry185" relatedClass={relValue} relatedTermTitle="mixed gender classrooms"/>

    </div>
<p class={paragraph}><br></br><br></br>That's all for this tutorial. I hope that by exploring the dictionary, you will find the best ways of discovering information. If you have any questions, please feel free to use my contact page <a href="https://laurensadow.com/?page_id=35">here</a>. </p>

    </div>
    </Layout>
  )
}

export default HelpPage