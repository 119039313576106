import * as React from 'react'
import {
    exampleText,
    // exampleRef
  } from './layout.module.css'

// const Example = ({ exampleDate, exampleAuthor, exampleKind, exampleSource, exampleSourceID, hansardid, children }) => {


const Example = ({ exampleKind, children }) => {
  return (
    // <ExampleContainer>
    <div className={exampleKind}>
        <p className={exampleText}>"{children}"</p>
        {/* <p className={exampleRef}>{exampleAuthor}, {exampleDate}</p> */}
    </div>
    // </ExampleContainer>
  )
}

export default Example